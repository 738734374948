import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { GoTriangleLeft } from "react-icons/go"
import SEO from "../components/SEO"

export const query = graphql`
  query($slug: String!) {
    post: mdx(
      frontmatter: { slug: { eq: $slug }, type: { eq: "POST" } }
      fields: { show: { eq: true } }
    ) {
      frontmatter {
        title
        published_on(formatString: "MMM DD, YYYY")
        tags
      }
      body
    }
  }
`

const postTemplate = ({ data, pageContext }) => {
  const { title, published_on } = data.post.frontmatter
  const { body } = data.post
  return (
    <Layout>
      <SEO title={title} />
      <Wrapper>
        <div className="container">
          <AniLink fade className="breadcrumb" to="/posts">
            <GoTriangleLeft />
            <span> All Posts </span>
          </AniLink>
          <div className="post">
            <h1>{title}</h1>
            <div className="post-body">
              <div className="published-on">
                <span>Published on {published_on}</span>
              </div>
              <hr />
              <MDXRenderer>{body}</MDXRenderer>
              <hr />
            </div>
          </div>
          <AniLink fade className="breadcrumb" to="/">
            <GoTriangleLeft />
            <span> All Posts </span>
          </AniLink>
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ul,
  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li {
      margin-bottom: 0.4em;
    }
  }

  h1 {
    text-align: center;
  }

  .more {
    margin-top: 2em;
  }

  .breadcrumb {
    margin: 2em 0 2em 2em;
    list-style-type: none;
    transition: var(--mainTransition);
    color: var(--mainBlack);

    svg {
      font-size: 0.8rem;
    }
  }

  .post-body {
    padding: 0 10%;
    text-align: left;
    font-size: 1.04em;
    margin: 1em auto 0px;
    align-items: flex-start;
    margin: 0 auto;

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        margin-bottom: 0.4em;
      }
    }
  }

  .published-on {
    display: flex;
    justify-content: center;

    span {
      color: gray;
      font-weight: 1rem;
      padding-bottom: 12px;
    }
  }

  @media screen and (max-width: 500px) {
    .post-body {
      padding: 0 1em;
    }
  }
`

export default postTemplate
